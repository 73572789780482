(function() {

    var app = {
        validate: function(form) {
            var errors = [];

            // clear input and textarea styles
            form.find("input").removeAttr("style");
            form.find("textarea").removeAttr("style");
            form.find("select").removeAttr("style");

            // check inputs
            if (form.find("input").length) {
                form.find("input").each(function(i) {
                    if (!$(this).val() && typeof $(this).attr("required") !== "undefined") {
                        $(this).css("background-color", "#ffdedb");
                        errors.push(i);
                    }

                    if ($(this).attr("name") == 'email' && typeof $(this).attr("required") !== "undefined") {
                        var email = new RegExp("^(([a-zA-Z0-9])|([-]|[_]|[.]))+[@](([a-zA-Z0-9])|([-])){2,63}[.](([a-zA-Z0-9]){2,63})+$", "i").exec($(this).val());

                        if (!email) {
                            $(this).css("background-color", "#ffdedb");
                            errors.push(i);
                        }
                    }
                });
            }

            // check textareas
            if (form.find("textarea").length) {
                form.find("textarea").each(function(i) {
                    if (!$(this).val() && typeof $(this).attr("required") !== "undefined") {
                        $(this).css("background-color", "#ffdedb");
                        errors.push(i);
                    }
                });
            }

            // check select
            if (form.find("select").length) {
                form.find("select").each(function(i) {
                    if (!$(this).val() && typeof $(this).attr("required") !== "undefined") {
                        $(this).css("background-color", "#ffdedb");
                        errors.push(i);
                    }
                });
            }

            if (errors.length)
                return false;

            return true;
        },
        request: function(data) {
            $.ajax({
                url: data.url,
                type: (typeof data.type !== 'undefined' && data.type !== '' ? data.type : 'POST'),
                data: data.data,
                context: (typeof data.context !== 'undefined' && data.context !== '' ? data.context : ''),
                processData: (typeof data.processData !== 'undefined' && data.processData !== '' ? data.processData : true),
                contentType: (typeof data.contentType !== 'undefined' && data.contentType !== '' ? data.contentType : 'application/x-www-form-urlencoded; charset=UTF-8'),
                beforeSend: function() {
                    return typeof data.beforeSend !== 'undefined' ? data.beforeSend() : null;
                },
                success: function(res) {
                    return data.success(res);
                },
                error: function(res) {
                    console.log(res);
                }
            });
        },
        add_price_from_fields: function() {
            var calc_form = $('#form-price-calculator');
            var price_form = $('#form-price-order');

            if (calc_form.find('select[name="circulation"] option:selected').val()) {
                price_form.find('input[name="circulation"]').val(calc_form.find('select[name="circulation"] option:selected').val());
            }

            if (calc_form.find('select[name="format"] option:selected').val()) {
                price_form.find('input[name="format"]').val(calc_form.find('select[name="format"] option:selected').text());
            }

            if (calc_form.find('select[name="personalizate"] option:selected').val()) {
                price_form.find('input[name="personalizate"]').val(calc_form.find('select[name="personalizate"] option:selected').text());
            }

            if (calc_form.find('select[name="lamination"] option:selected').val()) {
                price_form.find('input[name="lamination"]').val(calc_form.find('select[name="lamination"] option:selected').text());
            }

            if (calc_form.find('input[name="additional[lamination]"]').is(':checked')) {
                price_form.find('input[name="lamination"]').val(calc_form.find('input[name="additional[lamination]"]').val());
            } else {
                price_form.find('input[name="lamination"]').val('');
            }

            if (calc_form.find('input[name="additional[roundingcorners]"]').is(':checked')) {
                price_form.find('input[name="roundingcorners"]').val(calc_form.find('input[name="additional[roundingcorners]"]').val());
            } else {
                price_form.find('input[name="roundingcorners"]').val('');
            }

            if (calc_form.find('select[name="springs"] option:selected').val()) {
                price_form.find('input[name="springs"]').val(calc_form.find('select[name="springs"] option:selected').text());
            }

            if (calc_form.find('select[name="adblock"] option:selected').val()) {
                price_form.find('input[name="adblock"]').val(calc_form.find('select[name="adblock"] option:selected').text());
            }

            if (calc_form.find('select[name="sheetnum"] option:selected').val()) {
                price_form.find('input[name="sheetnum"]').val(calc_form.find('select[name="sheetnum"] option:selected').text());
            }

            if (calc_form.find('select[name="pechat"] option:selected').val()) {
                price_form.find('input[name="pechat"]').val(calc_form.find('select[name="pechat"] option:selected').text());
            }

            if (calc_form.find('select[name="roundingcorners"] option:selected').val()) {
                price_form.find('input[name="roundingcorners"]').val(calc_form.find('select[name="roundingcorners"] option:selected').text());
            }

            if (calc_form.find('select[name="stitching"] option:selected').val()) {
                price_form.find('input[name="stitching"]').val(calc_form.find('select[name="stitching"] option:selected').text());
            }

            if (calc_form.find('select[name="paper"] option:selected').val()) {
                price_form.find('input[name="paper"]').val(calc_form.find('select[name="paper"] option:selected').text());
            }

            if (calc_form.find('input[name="color_plastic"]:checked').val()) {
                price_form.find('input[name="color_plastic"]').val(calc_form.find('input[name="color_plastic"]:checked').val());
            }

            if (calc_form.find('input[name="color_printing"]:checked').val()) {
                price_form.find('input[name="color_printing"]').val(calc_form.find('input[name="color_printing"]:checked').val());
            }

            if (calc_form.find('input[name="personalization[]"]:checked').length) {
                var arr = [];
                for (var i = 0; i < calc_form.find('input[name="personalization[]"]:checked').length; i++) {
                    arr.push(calc_form.find('input[name="personalization[]"]:checked')[i].value);
                }
                price_form.find('input[name="personalization"]').val(arr.join(','));
            }

            if (calc_form.find('input[name="packaging"]:checked').val()) {
                price_form.find('input[name="packaging"]').val(1);
            }

            if ($('#price-total').text()) {
                price_form.find('input[name="total"]').val($('#price-total').text());
            }

            if ($('#price-card').text()) {
                price_form.find('input[name="price"]').val($('#price-card').text());
            }

            if ($('#production-time').text()) {
                price_form.find('input[name="time"]').val($('#production-time').text());
            }

            if (calc_form.find('select[name="name_unit"] option:selected').val()) {
                price_form.find('input[name="name_unit"]').val(calc_form.find('select[name="name_unit"] option:selected').text());
            }
        },
    };

    $(document).ready(function() {
        if ($('.js_home-slider').length) {
            $('.js_home-slider').owlCarousel({
                items: 1,
                navigation: false,
                itemsDesktop: [1199, 1],
                itemsDesktopSmall: [992, 1],
                itemsTablet: [768, 1],
                itemsMobile: [767, 1],
            });
        }
        if ($('input[name="phone"]')) {
            $('input[name="phone"]').mask('+7 (999) 999 99-99');
        }
        setTimeout(function() {
            $('.js_price-form').change();
        }, 500);
    });

    // sidebar menu
    $(document).on('click', '.js_sidebar-menu .icon', function() {
        var li = $(this).parents('li');
        if (li.hasClass('active')) {
            li.removeClass('active');
            li.find('.sidebar-sub-menu').slideUp(200);
        } else {
            li.addClass('active');
            li.find('.sidebar-sub-menu').slideDown(200);
        }
    });

    // send form with file
    $(document).on('click', '.js_send-form', function(e) {
        e.preventDefault();

        var form = $($(this).attr('data-form'));

        if (!app.validate(form)) {
            return false;
        }

        var data = new FormData();

        if (form.find('input[name="file"]').length && form.find('input[name="file"]').val() !== '') {

            var file = form.find('input[name="file"]').prop('files')[0];

            if (file.size >= 1024 * 1024 * 5) {
                form.find('.form-result').empty().html('<b>Ошибка! Файл не должен быть больше 5 MB!</b>');
                return false;
            }

            data.append('file', form.find('input[name="file"]').prop('files')[0]);
        }

        form.find('input[type="text"]').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        form.find('input[type="email"]').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        form.find('input[type="tel"]').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        form.find('input[type="hidden"]').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        form.find('input[type="checkbox"]:checked').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        form.find('input[type="radio"]:checked').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        form.find('select').each(function() {
            data.append($(this).attr('name'), $(this).find('option:selected').val());
        });

        form.find('textarea').each(function() {
            data.append($(this).attr('name'), $(this).val());
        });

        return app.request({
            url: form.attr('action'),
            data: data,
            context: $(this),
            processData: false,
            contentType: false,
            beforeSend: function() {
                this.context.attr('disabled', true);
            },
            success: function(res) {

                console.log(res);

                res = JSON.parse(res);
                this.context.attr('disabled', false);

                var form = $(this.context.attr('data-form'));

                form.find('.js_clear-after').each(function() {
                    $(this).val('');
                });

                if (res.status === 200) {
                    form.find('.form-result').empty().html('<p><b>Заявка успешно отправлена!</b></p>');
                }

                if (res.status === 400) {
                    form.find('.form-result').empty().html('<p><b>Ошибка! Попробуйте еще раз!</b></p>');
                }
            }
        });
    });

    // calculate price
    $(document).on('change', '.js_price-form', function(e) {
        e.preventDefault();

        var calculator_type = $(this).find('#calculator_type'),
            current = calculator_type.attr('data-current'),
            url = calculator_type.find('option:selected').attr('data-url');

        if (current !== url) {
            return location = url;
        }

        if (!app.validate($(this))) {
            return false;
        }

        return app.request({
            url: $(this).attr('action'),
            data: $(this).serialize(),
            context: $(this),
            beforeSend: function() {},
            success: function(res) {
                console.log(res);

                if (res.status === 200) {
                    $('#error-data').empty();
                    $('#price-total').empty().text(res.total);
                    $('#price-card').empty().text(res.price);
                    $('#production-time').empty().text(res.time);
                }

                if (res.status === 400 && typeof res.data && res.data !== '') {
                    $('#error-data').empty().text(res.data);
                    $('#price-total').empty();
                    $('#price-card').empty();
                    $('#production-time').empty();
                    $('input[name="price"]').val('');
                    $('input[name="total"]').val('');
                }

                app.add_price_from_fields();
            }
        });
    });

    $(document).on('change', '.js_pechat-select', function() {
        var val = +$(this).find('option:selected').val();
        if (val === 1) {
            $('.js_paper-select').find('option').show();
            $('.js_circulation-select').find('option').show();
            $('.js_circulation-select').find('option').each(function(i) {
                if (+$(this).attr('data-type') === 2) {
                    $(this).hide();
                }
                if (+$(this).val() === 50) {
                    $(this).prop('selected', true);
                }
            });
        } else {
            $('.js_paper-select').find('option').hide();
            $('.js_paper-select').find('option').each(function() {
                if (+$(this).attr('data-type') === 2) {
                    $(this).show();
                }
                if (+$(this).val() === 1) {
                    $(this).prop('selected', true);
                }
            });

            $('.js_circulation-select').find('option').hide();
            $('.js_circulation-select').find('option').each(function(i) {
                if (+$(this).attr('data-type') === 2) {
                    $(this).show();
                }
                if (+$(this).val() === 1000) {
                    $(this).prop('selected', true);
                }
            });
        }
    });
}($));